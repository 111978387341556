import Helmet from "react-helmet"
import React from "react"
import AmoTyp from "src/components/amotyp"
import Data from "./data/data.json"
import SiteData from "branding/site-metadata.json"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single.js"



export default function ThankYouAmo() {
  return(
    <>
    <Helmet>
     <style type="text/css">{`
       .footerlinkssingle-module--br--SJsBa {
         position: absolute;
         bottom: 10px;
         left: 50%;
         transform: translateX(-50%);
       }

       .amotyp-module--amoContainer img#amotyp-module--logo {
         height:40px;
       }

       .amotyp-module--amoContainer #amotyp-module--imsg {
         padding:20px 80px;
       }
       .amotyp-module--amoContainer span#amotyp-module--installMsg, #amotyp-module--imsg span {
         font-size:56px;
         font-weight:bold;
         color: #024059;
         line-height:125%;
       }
       img#amotyp-module--logo--GtTlw {
         height: 40px!important;
       }
       .amotyp-module--amoContainer .amotyp-module--aCard, .amotyp-module--amoContainer .amotyp-module--aUp {
         top:160px;
       }
       .tyTop {
         background:#d9edfd;
         padding:0 0 80px 0;
         min-height:75vh;
       }
       .tyTop p {
         font-size:28px;
         padding:0 80px;
         width:60%;
       }
       .amotyp-module--amoContainer .amotyp-module--aCard p {
         width:100%;
       }
       .tyBottom {
         display:flex;
         flex-wrap:wrap;
         justify-content:center;
         padding:50px 0;
       }
       .tyBottom div {
         width:50%;
       }
       .tyBottom h2 {
        flex-basis:100%;
        font-size:40px;
        font-weight:bold;
        color:#580004;
        line-height:125%;
        padding:0 0 65px 0;
        text-align:center;
       }
       .tyBottom div {
         padding:0 0 0 80px;
       }
       .tyBottom div:last-child {
        text-align:center;
        padding:0;
       }
       .tyBottom p, .tyBottom ul {
        font-size:28px;
      }

    }
    `}
    </style>
    <title>Thank you for downloading</title>
    </Helmet>

    <section>
      <div class="tyTop">
      <AmoTyp data={Data} siteData={SiteData}></AmoTyp>
      </div>
      <div class="tyBottom">
      <h2>Easy Save Does the Work for You</h2>
      <div>
      <strong><p>2 Ways to Save:</p></strong>
      <ul>
      <li>Allow Easy Save to Read URL for One-Click Download <br></br><i>(click "Yes" on consent screen)</i></li>
      <li>Copy and paste web address into modal to save as PDF!</li>
      </ul>
      <p>Start now by clicking the extension icon!</p>
      </div>
      <div>
        <img src="../../img/bam-no-icon.jpg" alt="Easy Save"></img>
      </div>
      </div>
      <FooterlinksSingle></FooterlinksSingle>
    </section>
    </>
  )
}

import React from "react"
import Styles from "./css/amotype.module.scss"
import {isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


class Amotyp extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      pageData: {},
      data: {},
      siteData: {}
    }
  }
  componentDidMount(){
    let data = this.props.data;
    let siteData = this.props.siteData;
    this.setState({data: data, siteData: siteData});
    if(isChrome()){
      const browserData = Object.assign(data, data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(data, data.firefox);
      this.setState({data: browserData});
    }
  }

  render() {
    return (
      <div className={Styles.amoContainer}>
      <div className={Styles.stepA}>
               <img className={Styles.aUp} src="/img/arrow-up.png" />
               <div className={Styles.aCard}>
                   <h1>{this.state.data.headerTitle}</h1>
                   <ul>
                    <li>Look for the Easy Save Icon <img src={this.state.data.logoIcon} alt="Icon" /></li>
                    <li><span>Can't find it?</span> Click the <img src={this.state.data.puzzle} alt="Puzzle" />icon to expand the list of your extensions</li>
                   </ul>
               </div>
           </div>


           <p id={Styles.imsg}>
   			<img src={this.state.siteData.headerLogo} id={Styles.logo}/>
               <span id={Styles.installMsg}>Thank you for installing</span>
               <span> {this.state.data.productName} </span>
           </p>

      </div>
    )
  }
}

export default Amotyp